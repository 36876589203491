import '../styles/index.scss';
import transparentTemplate from '../templates/transparent.html';
import interact from 'interactjs';
import linkIcon from '../images/link.svg';
import checkIcon from '../images/check.svg';
import '../main.css';

const CDN_URL = process.env.CDN_URL;
const CDN_PATH_V1 = 'bundle.js';
const CDN_PATH_V2 = 'v2/widget.js';

const MOBILE_SCREEN_WIDTH = 425;
const TABLET_SCREEN_WIDTH = 768;
const DESKTOP_SCREEN_WIDTH = 1444;

const frameElement = document.getElementById('frame');
const frameContainer = document.getElementById("frame-container");

const state = {
    screen: null,
    chatbotKey: null,
    environmentId: null,
    custom: false,
    version: 1,
};

function getCdnUrl() {
    const path = state.version === 1 ? CDN_PATH_V1 : CDN_PATH_V2;
    if (state.custom) {
        return `${CDN_URL}/${state.chatbotKey}/${path}`;
    }
    return `${CDN_URL}/${path}`;
}

function getClientKey() {
    return window.location.host.includes("melibo.de") ? 'Melibo Editor' : 'Melibo Editor - STAGE';
}

function getScreenWidth(value) {
    switch (value) {
        case 'mobile':
            return `${MOBILE_SCREEN_WIDTH}px`;
        case 'tablet':
            return `${TABLET_SCREEN_WIDTH}px`;
        case 'desktop':
            return `${DESKTOP_SCREEN_WIDTH}px`;
        default:
            return '100%';
    }
}

function setScreen(screen) {
    const listElements = document.querySelectorAll("#button-container > li");
    listElements.forEach((el) => el.classList.remove("selected-button"));

    const buttonElements = document.querySelectorAll("#button-container > li > button");
    buttonElements.forEach((el) => el.classList.remove("active"));

    const buttonElement = document.getElementById(`button-screen-${screen}`);

    buttonElement.classList.add("active");
    buttonElement.parentElement.classList.add("selected-button");

    const width = getScreenWidth(screen);
    frameContainer.style.width = width;
}

function setTemplate() {
    const frameDoc = frameElement.contentDocument || frameElement.contentWindow.document;
    frameDoc.open();
    frameDoc.write(transparentTemplate);
    frameDoc.close();
}

function main() {
    const params = new URLSearchParams(window.location.search);
    const chatbotKey = params.get("chatbotKey");
    const screen = params.get("screen");
    const environmentId = params.get("environmentId");
    const custom = params.get("custom");
    // v = version
    const version = params.get("v");
    state.screen = screen;
    state.chatbotKey = chatbotKey;
    state.environmentId = environmentId;
    state.custom = custom !== null;
    state.version = version ? Number(version) : 1;

    const localStorageChatbotKey = localStorage.getItem('chatbot_key');
    if (localStorageChatbotKey !== chatbotKey) {
        localStorage.clear();
        localStorage.setItem('chatbot_key', chatbotKey);
    }

    frameElement.addEventListener("load", () => {
        frameElement.contentWindow.postMessage({
            chatbotKey,
            url: getCdnUrl(),
            environmentId,
            clientKey: getClientKey(),
            version: state.version
        }, '*');
    });
    setTemplate();
    if (screen) {
        setScreen(screen);
    }
}

function handleChangeParams() {
    const params = new URLSearchParams(window.location.search);
    const screen = params.get("screen");
    if (screen !== state.screen) {
        setScreen(screen);
    }
    state.screen = screen;

}

function setParam(name, value) {
    const url = new URL(window.location);
    url.searchParams.set(name, value);
    window.history.replaceState({}, '', url);
    handleChangeParams();
}

/*  */
frameElement.setAttribute('height', frameContainer.clientHeight);


interact(frameContainer).resizable({
    edges: {
        top: false,
        left: false,
        bottom: false,
        right: true,
    }
}).on('resizemove', (event) => {
    const target = event.target;
    const width = event.rect.width;
    if (width < 320) {
        return;
    }
    target.style.width = event.rect.width + 'px';
});

document.getElementById("button-screen-mobile").addEventListener("click", () => {
    setParam('screen', 'mobile');
});
document.getElementById("button-screen-tablet").addEventListener("click", () => {
    setParam('screen', 'tablet');
});
document.getElementById("button-screen-desktop").addEventListener("click", () => {
    setParam('screen', 'desktop');
});

const copyButton = document.getElementById("button-copy");
const imageElement = document.createElement("img");
imageElement.src = linkIcon;
const spanElement = document.createElement("span");
spanElement.textContent = copyButton.dataset.default;
copyButton.appendChild(imageElement);
copyButton.appendChild(spanElement);
copyButton.addEventListener("click", () => {
    navigator.clipboard.writeText(location.href).then(() => {
        imageElement.src = checkIcon;
        spanElement.textContent = copyButton.dataset.copied;
        setTimeout(() => {
            imageElement.src = linkIcon;
            spanElement.textContent = copyButton.dataset.default;
        }, 1500);
    });
});

main();
